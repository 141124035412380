<template>
  <div id="smallNav">
    <div class="it-header-wrapper fixed-top" id="navParent">
      <div class="it-nav-wrapper">
        <div
          class="it-header-navbar-wrapper"
          :style="
            { 'background-color': theme.primary_bg, 'z-index': 1000 }
          "
          id="botNav"
        >
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <nav class="navbar navbar-expand-lg has-megamenu">
                  <div style="z-index: 1000; min-width:100%">
                    <div
                      class="overlay"
                      @click="closeMenu"
                      style="display: none;"
                    ></div>
                    <div
                      class="menu-wrapper p-0 d-lg-flex align-items-lg-center"
                      :style="{ 'background-color': theme.primary_bg }"
                      v-if="showMenu"
                    >
                      <span>
                        <logo
                          src="/assets/logo.svg"
                          class="clone"
                        ></logo>
                      </span>
                      <div class="row d-lg-none align-items-center px-1 py-3">
                        <div
                          class="col-9 d-flex justify-content-center"
                        >
                          <nome-istituto
                            :name="this.parameters.name"
                            :inline="false"
                            class="nome-istituto"
                            :style="{ color: theme.primary_txt }"
                          ></nome-istituto>
                        </div>
                        <div class="col-12">
                          <div class="row pt-3">
                            <div
                              class="col-6 d-flex align-items-center justify-content-center"
                            >
                              <a
                                @click="$emit('showSearch')"
                                class="link"
                                aria-label="Cerca"
                                href="#"
                                :style="{
                                  'background-color': theme.primary_bg,
                                  color: theme.primary_txt
                                }"
                              >
                                <svg
                                  class="icon-md"
                                  :style="{ fill: theme.primary_txt }"
                                >
                                  <use
                                    xlink:href="/bootstrap-italia/dist/svg/sprite.svg#it-search"
                                  />
                                </svg>
                              </a>
                            </div>
                            <div
                              class="col-6 d-flex align-items-center justify-content-center"
                            >
                              <span
                                class="link"
                                :style="{
                                  'background-color': theme.primary_bg,
                                  color: theme.primary_txt
                                }"
                              >
                                <font-awesome-icon
                                  icon="user-circle"
                                  size="2x"
                                ></font-awesome-icon>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <span
                        class="container-fluid d-flex align-items-center justify-content-between"
                      >
                        <span class="d-flex">
                          <Menu
                            :links="
                              menus.left
                            "
                            :secondary="false"
                            :mega="false"
                          ></Menu>
                        </span>
                        <span class="d-flex align-items-center">
                          <Menu
                            :links="
                              menus.right
                            "
                            :secondary="true"
                            :mega="false"
                          ></Menu>
                          <a
                            @click="$emit('showSearch')"
                            class="link rounded-icon bg-transparent clone ml-auto"
                            aria-label="Cerca"
                            href="#"
                          >
                            <svg
                              class="icon"
                              :style="{ fill: theme.primary_txt }"
                            >
                              <use
                                xlink:href="/bootstrap-italia/dist/svg/sprite.svg#it-search"
                              />
                            </svg>
                          </a>
                        </span>
                      </span>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from "./Logo";
import NomeIstituto from "./NomeIstituto";
import Menu from "./Menu";

export default {
  data() {
    return {
      theme: {
        primary_txt: this.parameters.primary_txt,
        primary_bg: this.parameters.primary_bg,
        secondary_txt: this.parameters.secondary_txt,
        secondary_bg: this.parameters.secondary_bg
      },
      menus: this.menusImport,
      showMenu: false
    };
  },
  props: {
    menusImport: Object,
    parameters: Object
  },
  components: {
    Logo,
    NomeIstituto,
    Menu
  },
  created() {
    $(window)
      .resize(this.closeMenu)
      .scroll(this.smallNav);
  },
  beforeUnmount() {
    $(window).off();
  },
  methods: {
    smallNav() {
      let w = $(window);

      if (w.scrollTop() > $(".it-nav-wrapper").height()) {
        this.showMenu = true;
        $("#smallNav").show();
      } else {
        this.showMenu = false;
        $("#smallNav").hide();
      }
    },
    closeMenu() {
      $('div[id^="toggle-"]').slideUp(100);
      $('a[id^="item-"]').removeClass("selected");
    }
  }
};
</script>
<style scoped>
.it-header-wrapper {
  box-shadow: 0 0.1rem 1.55rem rgba(0, 0, 0, 0.1);
}

.link:hover {
  opacity: 0.8;
}

.icon-md {
  width: 32px !important;
  height: 32px !important;
}

.it-nav-wrapper {
  min-width: 100vw;
}

#smallNav {
  display: none;
}

#navParent {
  display: none;
}

@media (min-width: 992px) {
  #navParent {
    display: flex;
  }
}
</style>
