<template>
  <ul
    :class="['navbar-nav', 'p-0', 'my-0', { 'ml-auto': secondary }]"
    style="font-size: 13pt"
    v-if="!mega"
  >
    <div
      v-for="(section, index) in links.menu.menu_sections"
      :key="index"
      class="d-flex align-items-center"
    >
      <li
        class="nav-item"
        v-if="section.menu_elements.length === 0 || section.page_id"
      >
        <a
          class="nav-link font-weight-semibold py-3 px-2"
          v-if="section.page_id"
          href="#"
          target="_self"
          :style="{ color: links.theme.primary_txt, border: 'none' }"
          @click="closeNav()"
          >{{ section.name }}</a
        >
        <a
          href="#"
          target="_self"
          class="nav-link font-weight-semibold py-3 px-2"
          :style="{ color: links.theme.primary_txt, border: 'none' }"
          @click="closeNav()"
          v-else
          >{{ section.name }}</a
        >
      </li>
      <li
        class="nav-item dropdown megamenu"
        v-on:click.self.prevent
        v-else
      >
        <a
          class="nav-link font-weight-semibold py-3 px-2"
          :id="'item-' + section.id"
          href="#"
          v-on:click.self.prevent
          @click="toggleMenu(section.id)"
          aria-expanded="false"
          :style="{ color: links.theme.primary_txt, 'border-color': links.theme.primary_txt, border: 'none' }"
        >
          {{ section.name }}
          <svg
            class="icon icon-xs"
            :style="{ fill: links.theme.primary_txt }"
            v-on:click.self.prevent
          >
            <use xlink:href="/bootstrap-italia/dist/svg/sprite.svg#it-expand" />
          </svg>
        </a>
        <div
          class="dropdown-menu m-0 mega"
          :id="'toggle-' + section.id"
          :style="{ 'background-color': links.theme.primary_bg }"
        >
          <div class="row">
            <div
              class="col-md-4 link-list-wrapper"
              :style="{ 'border-color': links.theme.primary_txt + '!important' }"
              :class="
                section.menu_elements.length > section.links_per_col * 3
                  ? i <=
                    (Math.ceil(
                      section.menu_elements.length / section.links_per_col / 3
                    ) -
                      1) *
                      3
                    ? 'mb-4'
                    : ''
                  : ''
              "
              v-for="i in Math.ceil(
                section.menu_elements.length / section.links_per_col
              )"
              :key="i"
            >
              <ul class="link-list">
                <li
                  v-for="element in section.menu_elements.slice(
                    (i - 1) * section.links_per_col,
                    i * section.links_per_col
                  )"
                  :key="element.id"
                  class="py-1"
                >
                  <a
                    v-if="element.page_id"
                    href="#"
                    target="_self"
                    class="list-item"
                    :style="{ color: links.theme.primary_txt }"
                    @click="closeNav()"
                    >{{ element.label }}</a
                  >
                  <a
                    href="#"
                    target="_self"
                    class="list-item"
                    :style="{ color: links.theme.primary_txt }"
                    @click="closeNav()"
                    v-else
                    >{{ element.label }}</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </li>
    </div>
  </ul>
</template>

<script>
export default {
  name: "Menu",
  data() {
    return {
      elementsPerColumn: 5
    };
  },
  props: {
    links: Object,
    secondary: Boolean,
    mega: Boolean
  },
  methods: {
    convertToSlug(Text) {
      return Text.toLowerCase()
        .replace(/[^\w ]+/g, "")
        .replace(/ +/g, "-");
    },
    checkWidth() {
      return $(window).width() >= 992;
    },
    toggleMenu(index) {
      this.closeMenu();
      if ($("#toggle-" + index).css("display") === "none") {
        if (this.checkWidth()) {
          $("#item-" + index).addClass("selected");
        }
        $("#toggle-" + index).slideToggle(100);
      }
    },
    closeMenu() {
      $('div[id^="toggle-"]').slideUp(100);
      $('a[id^="item-"]').removeClass("selected");
    },
    closeNav() {
      $(".overlay").trigger("click");
    }
  }
};
</script>

<style>
a.list-item:hover,
a.nav-link:hover {
  text-decoration: underline !important;
  opacity: 0.8;
}

.selected {
  border-bottom: solid 0.9mm !important;
}

.mega {
  max-height: 75vh;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
