<template>
  <div :style="{ 'background-color': data.theme.primary_bg }">
    <div class="row m-0">
      <div
        class="col-12 p-0 col-lg-6 d-none d-lg-block"
        :style="{ height: height + 'px', background: 'url(' + data.pattern + ')' }"
        v-if="data.half_hero"
      >
        <div class="desc-container container-fluid ml-auto mr-0">
          <transition name="slide-fade" mode="out-in">
            <div
              :class="[
                data.text_position,
                data.text_position === 'centered' ? 'text-center' : 'text-left'
              ]"
            >
              <span
                class="ck-content"
                :key="key"
                :style="{ color: data.theme.primary_txt }"
                v-html="file.description"
              ></span>
            </div>
          </transition>
        </div>
      </div>
      <div
        class="col-12 col-lg-6 p-0"
      >
        <carousel
          @change="loadDescription"
          :data="data"
          :height="height"
        ></carousel>
      </div>
    </div>
  </div>
</template>
<script>
import Splide from "./Splide";

export default {
  name: "Hero",
  components: {
    carousel: Splide
  },
  data() {
    return {
      data: {
        "id": 5,
        "theme_id": 83,
        "parallax": 1,
        "slider": 1,
        "automatic": 1,
        "mobile": 1,
        "time": 3,
        "order": 1,
        "height": 407,
        "overlay": 1,
        "manual_image_size": 100,
        "half_hero": 1,
        "pattern": "https://www.transparenttextures.com/patterns/cubes.png",
        "disable_slides": 0,
        "text_overlay": 1,
        "vertical_slide": 0,
        "text_position": "centered",
        "theme": {
            "primary_txt": "#000000",
            "primary_bg": "#ffffff",
        },
        "files": [
            {
                "name": "slide1",
                "path": "/assets",
                "extension": "jpg",
                "description": "<h2>#Impara con noi</h2>"
            },
            {
                "name": "slide2",
                "path": "/assets",
                "extension": "jpg",
                "description": "<h2>#Divertiti</h2>"
            },
            {
                "name": "slide3",
                "path": "/assets",
                "extension": "jpg",
                "description": "<h2>#Vola verso il tuo domani!</h2>"
            }
        ]
    },
      file: {
          "name": "slide1",
          "path": "/assets",
          "extension": "jpg",
          "description": "<h2>#Impara con noi</h2>"
      },
      key: 0,
      height: this.getHeight()
    };
  },
  methods: {
    getHeight() {
      let ratioHeight = ($(window).width() * 9) / 16;
      let maxHeight = 407;

      return ratioHeight > maxHeight ? maxHeight : ratioHeight;
    },
    loadDescription(data) {
      let file = this.data.files[data.index];
      this.key = file.id;
      this.file = file;
    },
    shadeColor(color, percent) {
      var R = parseInt(color.substring(1, 3), 16);
      var G = parseInt(color.substring(3, 5), 16);
      var B = parseInt(color.substring(5, 7), 16);

      R = parseInt((R * (100 + percent)) / 100);
      G = parseInt((G * (100 + percent)) / 100);
      B = parseInt((B * (100 + percent)) / 100);

      R = R < 255 ? R : 255;
      G = G < 255 ? G : 255;
      B = B < 255 ? B : 255;

      var RR =
        R.toString(16).length == 1 ? "0" + R.toString(16) : R.toString(16);
      var GG =
        G.toString(16).length == 1 ? "0" + G.toString(16) : G.toString(16);
      var BB =
        B.toString(16).length == 1 ? "0" + B.toString(16) : B.toString(16);

      return "#" + RR + GG + BB;
    }
  }
};
</script>
<style scoped>
.desc-container {
  position: relative;
  text-align: center;
  color: white;
  height: 100%;
}

/* Bottom left text */
.bottom-left {
  position: absolute;
  bottom: 8px;
  left: 16px;
}

/* Top left text */
.top-left {
  position: absolute;
  top: 8px;
  left: 16px;
}

/* Top right text */
.top-right {
  position: absolute;
  top: 8px;
  right: 16px;
}

/* Bottom right text */
.bottom-right {
  position: absolute;
  bottom: 8px;
  right: 16px;
}

/* Centered text */
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.slide-fade-enter-active {
  transition: all 0.1s ease;
}
.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
