<template>
  <main
  >
    <AppHeader :menus="menus" :parameters="parameters"></AppHeader>
    <div style="background-color: #f5f5f5">
    <div class="container-fluid">
      <div
        class="row d-flex align-items-start align-items-md-center justify-content-start w-100"
      >
        <div class="col-12 col-md-11">
          <div class="d-flex align-items-center justify-content-center m-2">
            <a type="button" class="view-btn view-btn-4 mx-2" href="/configure.html" target="_top">
              Personalizza
            </a>
            <button type="button" class="view-btn view-btn-1 mx-2" @click="goTo('desktop')" v-if="parameters.view !== 'desktop'" name="button">
              Desktop
            </button>
            <button type="button" class="view-btn view-btn-2 mx-2" @click="goTo('tablet')" v-if="parameters.view !== 'tablet'" name="button">
              Tablet
            </button>
            <button type="button" class="view-btn view-btn-3 mx-2" @click="goTo('phone')" v-if="parameters.view !== 'phone'" name="button">
              Telefono
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
    <div class="main" id="main" @click="closeMenu">
      <Alert />
      <Slides />
      <Bookmarks />
      <News />
      <Newses />
      <Partners />
    </div>
    <Footer :menus="menus" :parameters="parameters"></Footer>
  </main>
</template>
<script>

import AppHeader from "./components/ScuoleInCloud/AppHeader";
import Footer from "./components/ScuoleInCloud/Footer";
import Alert from "./components/ScuoleInCloud/Alert";
import Bookmarks from "./components/ScuoleInCloud/Bookmarks";
import News from "./components/ScuoleInCloud/News";
import Newses from "./components/ScuoleInCloud/Newses";
import Partners from "./components/ScuoleInCloud/Partners";
import Slides from "./components/ScuoleInCloud/Slides";

export default {
  name: "App",
  components: {
    AppHeader,
    Footer,
    Alert,
    Bookmarks,
    News,
    Newses,
    Slides,
    Partners
  },
  data() {
    return {
      parameters: {
        name: 'Istituto Comprensivo, Scuole in Cloud',
        primary_txt: '#000',
        primary_bg: '#fff',
        secondary_txt: '#000',
        secondary_bg: '#fff',
        view: 'desktop'
      },
      parametersList: ['name','primary_txt','primary_bg','secondary_txt','secondary_bg'],
      menus: {}
    }
  },
  beforeMount() {
    let params = new URLSearchParams(location.search);

    this.parametersList.forEach(item => {
      if(params.get(item)) {
        this.parameters[item] = decodeURI(params.get(item));
        //console.log(params.get(item));
      }
    });

    if(params.get('view')) {
      this.parameters['view'] = decodeURI(params.get('view'));
    }

    this.menus = {
      left: {
        "id": 978,
        "theme": {
          "primary_txt": this.parameters.primary_txt,
          "primary_bg": this.parameters.primary_bg,
          "secondary_txt": this.parameters.secondary_txt,
          "secondary_bg": this.parameters.secondary_bg
        },
        "menu": {
          "menu_sections": [
            {
              "id": 907,
              "page_id": true,
              "name": "Homepage",
              "links_per_col": 5,
              "menu_elements": []
            },
            {
              "id": 355,
              "name": "Le Scuole",
              "links_per_col": 5,
              "menu_elements": [
                {
                  "id": 837,
                  "page_id": true,
                  "label": "Scuola primaria Via San Quirino",
                },
                {
                  "id": 489,
                  "page_id": true,
                  "label": "Scuola Secondaria Via San Quirino"
                }
              ]
            },
            {
              "id": 157,
              "name": "L'Istituto",
              "links_per_col": 5,
              "menu_elements": [
                {
                  "id": 788,
                  "page_id": true,
                  "label": "Dirigente Scolastico"
                },
                {
                  "id": 776,
                  "page_id": true,
                  "label": "Segreteria"
                },
                {
                  "id": 467,
                  "page_id": true,
                  "label": "Circolari"
                }
              ]
            },
            {
              "id": 741,
              "name": "I Progetti",
              "links_per_col": 5,
              "menu_elements": [
                {
                  "id": 874,
                  "page_id": true,
                  "label": "PON 2014-2020"
                },
                {
                  "id": 677,
                  "page_id": true,
                  "label": "Didattica a Distanza"
                },
                {
                  "id": 25,
                  "page_id": true,
                  "label": "Corsi PSP"
                },
                {
                  "id": 840,
                  "page_id": true,
                  "label": "Scuole in Cloud"
                }
              ]
            },
            {
              "id": 905,
              "name": "Registro Elettronico",
              "links_per_col": 5,
              "menu_elements": [
                {
                  "id": 594,
                  "label": "Registro Elettronico Docenti",
                },
                {
                  "id": 830,
                  "label": "Registro Elettronico Famiglie",
                },
                {
                  "id": 980,
                  "label": "Albo online",
                }
              ]
            },
            {
              "id": 724,
              "name": "Giornalino",
              "links_per_col": 5,
              "menu_elements": [
                {
                  "id": 717,
                  "page_id": true,
                  "label": "Classici"
                }
              ]
            }
          ]
        }
      },
      right: {
        "id": 48,
        "theme": {
          "primary_txt": this.parameters.primary_txt,
          "primary_bg": this.parameters.primary_bg,
          "secondary_txt": this.parameters.secondary_txt,
          "secondary_bg": this.parameters.secondary_bg
        },
        "menu": {
          "id": 341,
          "menu_sections": [
            {
              "id": 548,
              "page_id": true,
              "name": "Contatti",
              "links_per_col": 5,
              "menu_elements": []
            },
            {
              "id": 73,
              "page_id": true,
              "name": "Privacy",
              "links_per_col": 5,
              "menu_elements": []
            },
            {
              "id": 418,
              "name": "Amm. trasparente",
              "links_per_col": 5,
              "menu_elements": []
            },
            {
              "id": 930,
              "name": "Albo Online",
              "links_per_col": 5,
              "menu_elements": []
            }
          ]
        }
      }
    };
  },
  methods: {
    goTo(target) {
      let uri = location.origin;

      if(target != 'desktop') {
        uri += '/' + target + '.html';
      }

      uri += '?';

      this.parametersList.forEach((item, i) => {
        uri += item + '=' + encodeURIComponent(this.parameters[item]);

        if(i !== this.parametersList.length - 1) {
          uri += '&';
        }
      });

      if(target !== 'desktop') {
        uri += '&view=' + target;
      }

      window.open(uri, '_top');
    },
    closeMenu() {
      $(".main").css({ filter: "brightness(100%)", transition: ".5s" });
      $('div[id^="toggle-"]').slideUp(100);
      $('a[id^="item-"]').removeClass("selected");
    }
  }
};
</script>

<style>
  .view-btn {
    border:none;
    position:relative;
    height:30px;
    font-size:1em;
    padding:0 1em;
    cursor:pointer;
    transition:500ms ease all;
    outline:none;
    border-radius: 2px;
  }

  .view-btn-1 {
    background:#1AAB8A;
    color:#fff;
  }

  .view-btn-1:hover {
    background:#fff;
    color:#1AAB8A;
  }

  .view-btn-1:before, .view-btn-1:after {
      background: #1AAB8A;
  }

  .view-btn-4 {
    background:#E6AF2E;
    color:#fff;
  }

  .view-btn-4:hover {
    background:#fff;
    color:#E6AF2E;
  }

  .view-btn-4:before, .view-btn-4:after {
      background: #E6AF2E;
  }

  .view-btn-3 {
    background:#D16666;
    color:#fff;
  }

  .view-btn-3:hover {
    background:#fff;
    color:#D16666;
  }

  .view-btn-3:before, .view-btn-3:after {
      background: #D16666;
  }

  .view-btn-2 {
    background:#4F7CAC;
    color:#fff;
  }

  .view-btn-2:hover {
    background:#fff;
    color:#4F7CAC;
  }

  .view-btn-2:before, .view-btn-2:after {
      background: #4F7CAC;
  }

  .view-btn:before, .view-btn:after{
    content:'';
    position:absolute;
    top:0;
    right:0;
    height:2px;
    width:0;
    transition:400ms ease all;
  }
  .view-btn:after{
    right:inherit;
    top:inherit;
    left:0;
    bottom:0;
  }
  .view-btn:hover:before, .view-btn:hover:after{
    width:100%;
    transition:500ms ease all;
  }
.cookie-btn {
  position: fixed;
  bottom: 64px;
  left: 16px;
  z-index: 999;
  border-radius: 50%;
  background-color: burlywood;
  color: white;
  cursor: pointer;
  text-align: center;
  display: table;
}
.cookie-icn {
  display: table-cell;
  vertical-align: middle;
}
span.fab-action-button,
a.accessibility-menu-item,
.cookie-btn {
  width: 40px !important;
  height: 40px !important;
}

span.fab-action-button > i.fab-action-button-icon {
  font-size: 24px !important;
}

span.fab-action-button {
  position: fixed !important;
  bottom: 16px !important;
  left: 16px !important;
}

div.accessibility-toolbar {
  position: fixed !important;
  bottom: 16px !important;
  left: 70px !important;
}

.container-fluid {
  max-width: 1280px !important;
}

.margin-fix {
  margin-top: 106px;
}

#main {
  flex: 1 0 auto;
}

@media (min-width: 1200px) {
  span.fab-action-button {
    bottom: 32px !important;
    left: 32px !important;
  }

  .cookie-btn {
    bottom: 80px !important;
    left: 32px !important;
  }

  div.accessibility-toolbar {
    bottom: 32px !important;
    left: 85px !important;
  }

  .margin-fix {
    margin-top: 105px;
  }
}

@media (min-width: 992px) {
  .margin-fix {
    margin-top: 164px;
  }
}
</style>
