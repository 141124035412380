<template>
  <div style="background-color: rgb(249, 249, 249); color: rgb(0, 0, 0);">
    <div class="container-fluid py-3">
      <div class="d-flex align-items-center">
        <h2 class="m-0 font-weight-semibold" style="overflow-wrap: break-word;">
          Comunicazioni e Avvisi
        </h2>
      </div>
      <div class="row d-flex justify-content-left">
        <div class="col-12 col-lg-4 col-md-6 py-3 px-3 d-flex justify-content-center">
          <div class="row shadow d-flex justify-content-center align-items-start rounded" style="min-width: 100%; background-color: rgb(255, 255, 255); color: rgb(0, 0, 0);">
            <div class="col-12 overflow-hidden p-0 rounded-top not-lateral" style='height: 250px;background-size: cover; background-position: center center; background-image: url("/assets/news1.jpg");'>
            </div>
            <div class="p-3 flex-column d-flex col-12">
              <div>
                <a href="#" style="color: rgb(0, 0, 0);">
                  <h4 class="font-weight-semibold m-0 h5">🗺 L'ABC della Scuola</h4>
                </a>
                <div class="break-word text-justify mt-4 cut-body">
                </div>
              </div>
              <div class="d-flex justify-content-between align-middle mt-3">
                <small class="d-flex">
                  <span>
                    <b>Pubblicato:</b>
                    13 ottobre 2021
                  </span>
                </small>
                <p class="d-flex justify-content-end">
                  <a href="#" class="font-weight-semibold" style="color: rgb(0, 0, 0);">
                    Leggi
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="15" fill="#000000">
                      <path d="M18.59 13H3a1 1 0 0 1 0-2h15.59l-5.3-5.3a1 1 0 1 1 1.42-1.4l7 7a1 1 0 0 1 0 1.4l-7 7a1 1 0 0 1-1.42-1.4l5.3-5.3z" class="heroicon-ui"></path>
                    </svg>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4 col-md-6 py-3 px-3 d-flex justify-content-center">
          <div class="row shadow d-flex justify-content-center align-items-start rounded" style="min-width: 100%; background-color: rgb(255, 255, 255); color: rgb(0, 0, 0);">
            <div class="col-12 overflow-hidden p-0 rounded-top not-lateral" style="height: 250px;background-size: cover; background-position: center center; background-image: url('./assets/news2.jpg');"></div>
            <div class="p-3 flex-column d-flex col-12">
              <div>
                <a href="#" style="color: rgb(0, 0, 0);">
                  <h4 class="font-weight-semibold m-0 h5">🆕 Nuova Notizia!</h4>
                </a>
                <div class="break-word text-justify mt-4 cut-body">
                </div>
              </div>
              <div class="d-flex justify-content-between align-middle mt-3">
                <small class="d-flex">
                  <span>
                    <b>Pubblicato:</b>
                    13 ottobre 2021
                  </span>
                </small>
                <p class="d-flex justify-content-end">
                  <a href="#" class="font-weight-semibold" style="color: rgb(0, 0, 0);">
                    Leggi
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="15" fill="#000000">
                      <path d="M18.59 13H3a1 1 0 0 1 0-2h15.59l-5.3-5.3a1 1 0 1 1 1.42-1.4l7 7a1 1 0 0 1 0 1.4l-7 7a1 1 0 0 1-1.42-1.4l5.3-5.3z" class="heroicon-ui"></path>
                    </svg>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4 col-md-6 py-3 px-3 d-flex justify-content-center">
          <div class="row shadow d-flex justify-content-center align-items-start rounded" style="min-width: 100%; background-color: rgb(255, 255, 255); color: rgb(0, 0, 0);">
            <div class="col-12 overflow-hidden p-0 rounded-top not-lateral" style="height: 250px;background-size: cover; background-position: center center; background-image: url('./assets/news3.jfif');">
            </div>
            <div class="p-3 flex-column d-flex col-12">
              <div>
                <a href="#" style="color: rgb(0, 0, 0);">
                  <h4 class="font-weight-semibold m-0 h5">🔔 Nuovo avviso!</h4>
                </a>
                <div class="break-word text-justify mt-4 cut-body">
                </div>
              </div>
              <div class="d-flex justify-content-between align-middle mt-3">
                <small class="d-flex">
                  <span>
                    <b>Pubblicato:</b>
                    13 ottobre 2021
                  </span>
                </small>
                <p class="d-flex justify-content-end">
                  <a href="#" class="font-weight-semibold" style="color: rgb(0, 0, 0);">
                    Leggi
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="15" fill="#000000">
                      <path d="M18.59 13H3a1 1 0 0 1 0-2h15.59l-5.3-5.3a1 1 0 1 1 1.42-1.4l7 7a1 1 0 0 1 0 1.4l-7 7a1 1 0 0 1-1.42-1.4l5.3-5.3z" class="heroicon-ui"></path>
                    </svg>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4 col-md-6 py-3 px-3 d-flex justify-content-center">
          <div class="row shadow d-flex justify-content-center align-items-start rounded" style="min-width: 100%; background-color: rgb(255, 255, 255); color: rgb(0, 0, 0);">
            <div class="col-12 overflow-hidden p-0 rounded-top not-lateral" style="height: 250px;background-size: cover; background-position: center center; background-image: url('./assets/news4.jpg');">
            </div>
            <div class="p-3 flex-column d-flex col-12">
              <div>
                <a href="#" style="color: rgb(0, 0, 0);">
                  <h4 class="font-weight-semibold m-0 h5">⏩ Lorem ipsum</h4>
                </a>
                <div class="break-word text-justify mt-4 cut-body">
                </div>
              </div>
              <div class="d-flex justify-content-between align-middle mt-3">
                <small class="d-flex">
                  <span>
                    <b>Pubblicato:</b>
                    12 ottobre 2021
                  </span>
                </small>
                <p class="d-flex justify-content-end">
                  <a href="#" class="font-weight-semibold" style="color: rgb(0, 0, 0);">
                    Leggi
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="15" fill="#000000">
                      <path d="M18.59 13H3a1 1 0 0 1 0-2h15.59l-5.3-5.3a1 1 0 1 1 1.42-1.4l7 7a1 1 0 0 1 0 1.4l-7 7a1 1 0 0 1-1.42-1.4l5.3-5.3z" class="heroicon-ui">
                      </path>
                    </svg>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4 col-md-6 py-3 px-3 d-flex justify-content-center">
          <div class="row shadow d-flex justify-content-center align-items-start rounded" style="min-width: 100%; background-color: rgb(255, 255, 255); color: rgb(0, 0, 0);">
            <div class="col-12 overflow-hidden p-0 rounded-top not-lateral" style="height: 250px;background-size: cover; background-position: center center; background-image: url('./assets/news5.jpg');"></div>
            <div class="p-3 flex-column d-flex col-12">
              <div>
                <a href="#" style="color: rgb(0, 0, 0);">
                  <h4 class="font-weight-semibold m-0 h5">🎈 Lorem ipsum</h4>
                </a>
                <div class="break-word text-justify mt-4 cut-body">
                </div>
              </div>
              <div class="d-flex justify-content-between align-middle mt-3">
                <small class="d-flex">
                  <span>
                    <b>Pubblicato:</b>
                    12 ottobre 2021
                  </span>
                </small>
                <p class="d-flex justify-content-end">
                  <a href="#" class="font-weight-semibold" style="color: rgb(0, 0, 0);">
                    Leggi
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="15" fill="#000000">
                      <path d="M18.59 13H3a1 1 0 0 1 0-2h15.59l-5.3-5.3a1 1 0 1 1 1.42-1.4l7 7a1 1 0 0 1 0 1.4l-7 7a1 1 0 0 1-1.42-1.4l5.3-5.3z" class="heroicon-ui"></path>
                    </svg>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4 col-md-6 py-3 px-3 d-flex justify-content-center">
          <div class="row shadow d-flex justify-content-center align-items-start rounded" style="min-width: 100%; background-color: rgb(255, 255, 255); color: rgb(0, 0, 0);">
            <div class="col-12 overflow-hidden p-0 rounded-top not-lateral" style="height: 250px;background-size: cover; background-position: center center; background-image: url('./assets/news6.jpg');">
            </div>
            <div class="p-3 flex-column d-flex col-12">
              <div>
                <a href="#" style="color: rgb(0, 0, 0);">
                  <h4 class="font-weight-semibold m-0 h5">*️⃣ Lorem ipsum</h4>
                </a>
                <div class="break-word text-justify mt-4 cut-body">
                </div>
              </div>
              <div class="d-flex justify-content-between align-middle mt-3">
                <small class="d-flex">
                  <span>
                    <b>Pubblicato:</b>
                    12 ottobre 2021
                  </span>
                </small>
                <p class="d-flex justify-content-end">
                  <a href="#" class="font-weight-semibold" style="color: rgb(0, 0, 0);">
                    Leggi
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="15" fill="#000000">
                      <path d="M18.59 13H3a1 1 0 0 1 0-2h15.59l-5.3-5.3a1 1 0 1 1 1.42-1.4l7 7a1 1 0 0 1 0 1.4l-7 7a1 1 0 0 1-1.42-1.4l5.3-5.3z" class="heroicon-ui"></path>
                    </svg>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center">
      </div>
    </div>
  </div>
</template>
