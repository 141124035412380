<template>
  <footer
    class="font-small"
    id="footer"
    :style="
      { 'background-color': theme.secondary_bg, color: theme.secondary_txt }
    "
  >
    <div class="container-fluid p-3" :style="cssProps">
      <div class="row d-flex justify-content-start align-items-center">
        <div class="col-12">
          <menu-footer
            :links="[
              menus.left,
              menus.right
            ]"
            :theme="theme"
          ></menu-footer>
        </div>
        <div
          class="border-top border-bottom border-secondary col-12 py-2"
          :style="{ 'border-color': theme.secondary_txt }"
        >
          <div
            v-for="element in contacts"
            :key="element.id"
          >
            <div class="col-12">
              <div class="row d-flex align-items-center mb-2">
                <logo
                  src="/assets/logo.png"
                ></logo>
                <nome-istituto
                  :name="element.contact.name"
                  class="ml-2"
                  :inline="true"
                ></nome-istituto>
              </div>
            </div>
            <div class="col-12 mb-4">
              <div class="row">
                <Maps
                  :frame="element.contact.maps"
                  :footer="true"
                  :id="element.contact.id"
                  class="col-12"
                  :pins="
                    element.contact.pins ? JSON.parse(element.contact.pins) : {}
                  "
                  :logo="true"
                ></Maps>
              </div>
            </div>
            <div class="col-12 mb-4 ck-content">
              <p
                class="m-0 secondary_txt"
                v-html="element.contact.description"
              ></p>
            </div>
            <div
              class="col-12 mb-4 d-flex justify-content-center align-items-center"
            >
              <div v-if="element.contact.telephone">
                <span
                  class="m-0"
                  v-for="(number, index) in element.contact.telephone.split(
                    ','
                  )"
                  :key="index"
                >
                  {{ number.trim() }}
                  <span
                    v-if="
                      index + 1 !==
                        element.contact.telephone.split(',').length ||
                        element.contact.email
                    "
                  >
                    -
                  </span>
                </span>
              </div>
              <span>&nbsp;</span>
              <div v-if="element.contact.email">
                <span
                  class="m-0"
                  v-for="(email, index) in element.contact.email.split(',')"
                  :key="index"
                >
                  <a
                    href="#"
                    :style="{ color: theme.secondary_txt }"
                    >{{ email.trim() }}</a
                  >
                  <span
                    v-if="index + 1 !== element.contact.email.split(',').length"
                  >
                    -
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div class="col-12 text-center">
            <a
              href="#"
              target="_blank"
              :style="
                {
                  color: theme.secondary_txt
                }
              "
              >Dichiarazione di accessibilità</a
            >
            <span>
              -
            </span>
            <a
              href="#"
              :style="
                {
                  color: theme.secondary_txt
                }
              "
              target="_self"
              >Consulta la Mappa del Sito</a
            >
            <span>
              -
            </span>
            <a
              href="#"
              role="button"
              data-toggle="modal"
              data-target="#legal-notes"
              :style="
                {
                  color: theme.secondary_txt
                }
              "
            >
              Note Legali
            </a>
            <!--MODAL-->
            <div
              class="modal fade"
              tabindex="-1"
              role="dialog"
              id="legal-notes"
              style="z-index: 9999"
            >
              <div
                class="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div class="modal-content rounded">
                  <div class="modal-header">
                    <h5 class="modal-title">Note Legali</h5>
                    <button
                      class="close"
                      id="close-cookie"
                      type="button"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <svg class="icon">
                        <use
                          xlink:href="/bootstrap-italia/dist/svg/sprite.svg#it-close"
                        ></use>
                      </svg>
                    </button>
                  </div>
                  <div class="modal-body max-height">
                    <div
                      class="d-flex align-items-center justify-content-between my-2"
                      v-for="(note, i) in legal_notes"
                      :key="i"
                    >
                      <h6
                        class="font-weight-semibold m-0 text-capitalize text-black"
                      >
                        {{ note.label }}
                      </h6>
                      <a
                        class="btn btn-sm rounded-lg shadow bg-primary text-white mr-2"
                        href="#"
                        target="_self"
                      >
                        Vedi
                      </a>
                    </div>
                  </div>
                  <div class="modal-footer mt-3 ml-auto">
                    <button
                      type="button"
                      class="btn btn-sm rounded-lg shadow text-black bg-100 mr-2"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      Chiudi
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12">
          <social
            :socialList="[]"
            :theme="theme"
          ></social>
        </div>
      </div>
    </div>
    <span
      class="footer-copyright d-flex align-items-center justify-content-center py-2"
      :style="
        { 'background-color': theme.primary_bg, color: theme.primary_txt }
      "
    >
      <a
        href="https://scuoleincloud.it/"
        target="_blank"
        :style="{ color: theme.primary_txt }"
      >
        <button
          type="button"
          class="font-weight-bold prysma d-flex align-items-center justify-content-center p-2"
        >
          <img
            src="/assets/logo-small.png"
            style="width: 22px"
            alt="Scuole In Cloud"
          />
          <span class="ml-2" :style="{ color: theme.primary_txt }"
            >Powered By Scuole in Cloud</span
          >
        </button>
      </a>
    </span>
  </footer>
</template>
<script>
import Logo from "./Logo";
import NomeIstituto from "./NomeIstituto";
import MenuFooter from "./MenuFooter";
import Social from "./Social";
import Maps from "./Maps";

export default {
  data() {
    return {
      theme: {
        primary_txt: this.parameters.primary_txt,
        primary_bg: this.parameters.primary_bg,
        secondary_txt: this.parameters.secondary_txt,
        secondary_bg: this.parameters.secondary_bg
      },
      legal_notes: [],
      contacts: [{
        "id": 620,
        "name": null,
        "insert_in_page": 0,
        "order": 1,
        "theme": {
          "id": 155,
          "primary_txt": this.parameters.primary_txt,
          "primary_bg": this.parameters.primary_bg,
          "secondary_txt": null,
          "secondary_bg": null
        },
        "cookies": [],
        "contact": {
          "id": 954,
          "name": this.parameters.name,
          "file_id": null,
          "header_image": 1,
          "maps": null,
          "telephone": "Pordenone PN, 0434 000000",
          "email": "support@prysma.it",
          "description": null,
          "pins": "{\"Scuole in Cloud\":{\"label\":{\"title\":\"Scuole in Cloud\",\"description\":null},\"x\":45.96733433628174,\"y\":12.65786092449288}}",
          "footer_map": 1,
          "file": null
        }
      }]
    };
  },
  props: {
    parameters: Object,
    menus: Object
  },
  components: {
    Social,
    Logo,
    NomeIstituto,
    MenuFooter,
    Maps
  },
  computed: {
    cssProps() {
      return {
        "--secondary_txt": this.theme.secondary_txt
      };
    }
  }
};
</script>
<style>
.prysma {
  background: transparent;
  border: none;
  border-radius: 20px;
}

.prysma:hover {
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  background-color: rgba(219, 219, 219, 0.1);
}

#footer {
  flex-shrink: 0;
}

.secondary_txt p {
  color: var(--secondary_txt) !important;
}
</style>
