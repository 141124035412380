<template>
  <div>
    <div class="it-header-wrapper" id="navParent">
      <div class="it-nav-wrapper">
        <div
          id="topNav"
          class="it-header-center-wrapper py-3 divider h-full"
          :style="
            { 'background-color': theme.primary_bg, 'min-height': '68px', 'z-index': 1000 }
          "
        >
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 d-flex align-items-center">
                <div>
                  <button
                    class="custom-navbar-toggler btn p-0"
                    aria-label="Apri/Chiudi Menù"
                    data-target="#nav-front"
                    aria-expanded="false"
                    aria-controls="nav-front"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4 6H20M4 12H20M13 18H20"
                        :stroke="theme.primary_txt"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                <div class="it-header-center-content-wrapper p-0">
                  <div class="it-brand-wrapper p-0 mx-3">
                    <span class="d-flex align-items-center" :style="{ color: theme.primary_txt }">
                      <logo
                        src="./assets/logo.png"
                        class="logo"
                      ></logo>
                      <nome-istituto
                        :name="this.parameters.name"
                        :inline="false"
                        class="nome-istituto"
                      ></nome-istituto>
                    </span>
                  </div>
                  <div class="ml-left d-md-flex d-none align-items-center">
                    <button
                      @click="$emit('showSearch')"
                      class="link-header btn btn-full btn-icon mt-1 my-1"
                      aria-label="Cerca"
                      :style="{
                        'background-color': theme.primary_bg,
                        color: theme.primary_txt
                      }"
                    >
                      <span
                        class="mr-3 font-weight-semibold"
                        :style="{ color: theme.primary_txt }"
                        >Cerca</span
                      >
                      <svg class="icon-md" :style="{ fill: theme.primary_txt }">
                        <use
                          xlink:href="/bootstrap-italia/dist/svg/sprite.svg#it-search"
                        />
                      </svg>
                    </button>
                    <button
                      class="btn btn-full btn-icon link-header my-1"
                      :style="{
                        'background-color': theme.primary_bg,
                        color: theme.primary_txt
                      }"
                    >
                      <span class="mr-2 font-weight-semibold">Accedi</span>
                      <svg
                        style="width: 32px; height: 32px"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="it-header-navbar-wrapper d-flex align-items-center"
          :style="
            { 'background-color': theme.primary_bg, 'min-height': '62px' }
          "
          id="botNav"
        >
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <nav class="navbar navbar-expand-lg has-megamenu p-0">
                  <div
                    class="navbar-collapsable"
                    id="nav-front"
                    :style="{ 'z-index': 1000 }"
                  >
                    <div class="overlay" style="display: none"></div>
                    <div
                      class="menu-wrapper p-0 d-lg-flex align-items-lg-center"
                      :style="{ 'background-color': theme.primary_bg }"
                    >
                      <div class="row d-lg-none align-items-center px-1 py-3">
                        <div class="col-12">
                          <div class="row pt-3">
                            <div
                              class="col-6 d-flex align-items-center justify-content-center"
                            >
                              <a
                                @click="$emit('showSearch')"
                                class="link-header"
                                aria-label="Cerca"
                                href="#"
                                :style="{
                                  'background-color': theme.primary_bg,
                                  color: theme.primary_txt
                                }"
                              >
                                <svg
                                  class="icon-md"
                                  :style="{ fill: theme.primary_txt }"
                                >
                                  <use
                                    xlink:href="/bootstrap-italia/dist/svg/sprite.svg#it-search"
                                  />
                                </svg>
                              </a>
                            </div>
                            <div
                              class="col-6 d-flex align-items-center justify-content-center"
                            >
                              <span
                                class="link-header"
                                :style="{
                                  'background-color': theme.primary_bg,
                                  color: theme.primary_txt
                                }"
                              >
                              <font-awesome-icon
                                  icon="user-circle"
                                  size="2x"
                                ></font-awesome-icon>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Menu
                        :links="menus.left"
                        :secondary="false"
                        :mega="false"
                      ></Menu>
                      <Menu
                        :links="menus.right"
                        :secondary="true"
                        :mega="false"
                      ></Menu>
                      <a
                        @click="$emit('showSearch')"
                        class="link-header rounded-icon bg-transparent clone ml-3"
                        aria-label="Cerca"
                        href="#"
                      >
                        <svg class="icon" :style="{ fill: theme.primary_txt }">
                          <use
                            xlink:href="/bootstrap-italia/dist/svg/sprite.svg#it-search"
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from "./Logo";
import NomeIstituto from "./NomeIstituto";
import Menu from "./Menu";

export default {
  data() {
    return {
      theme: {
        primary_txt: this.parameters.primary_txt,
        primary_bg: this.parameters.primary_bg,
        secondary_txt: this.parameters.secondary_txt,
        secondary_bg: this.parameters.secondary_bg
      },
      menus: this.menusImport,
      showMenu: true
    };
  },
  props: {
    menusImport: Object,
    parameters: Object
  },
  components: {
    Logo,
    NomeIstituto,
    Menu
  },
  created() {
    $(window)
      .resize(this.closeMenu)
      .scroll(this.smallNav);
  },
  beforeUnmount() {
    $(window).off();
  },
  methods: {
    smallNav() {
      let w = $(window);

      if (w.scrollTop() > $(".it-nav-wrapper").height() && w.width() > 992) {
        this.showMenu = false;
      } else {
        this.showMenu = true;
      }
    },
    closeMenu() {
      $('div[id^="toggle-"]').slideUp(100);
      $('a[id^="item-"]').removeClass("selected");
    },
    swipeClose() {
      $(".overlay").click();
    }
  }
};
</script>

<style scoped>
.divider {
  border-bottom: solid 0.5px rgba(255, 255, 255, 0.5);
}

.it-header-wrapper {
  box-shadow: 0 0.1rem 1.55rem rgba(0, 0, 0, 0.1);
}

.it-header-center-wrapper {
  height: auto !important;
}

.it-header-wrapper .it-nav-wrapper .it-header-navbar-wrapper {
  top: 0;
  margin-top: 0;
}

.clone {
  display: none;
}

.link-header:hover {
  opacity: 0.8;
}

.icon-md {
  width: 32px !important;
  height: 32px !important;
}
</style>
