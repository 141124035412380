<template>
  <img
    src="/assets/logo.svg"
    alt="logo"
    class="img-fluid my-2 mr-2"
    style="max-height: 45px"
  />
</template>

<script>
export default {
  name: "Logo",
  props: {
    src: String
  }
};
</script>

<style scoped>

</style>
