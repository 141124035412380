<template>
  <div>
    <splide
      :options="options"
      @splide:moved="changedSlide"
      @splide:click="link"
      class="p-0"
      style="z-index: 0"
      :slides="data.files"
      :key="update"
    >
      <splide-slide
        :style="cssVars"
        class="slide p-0"
        v-for="(slide, i) in data.files"
        :key="i"
      >
        <img
          :data-splide-lazy="
            '/assets/' + slide.name + '.' + slide.extension
          "
          :alt="slide.name"
        />
        <div
          class="black-overlay"
          :style="{ height: height + 'px' }"
          v-if="data.overlay && !data.text_overlay"
        ></div>

        <div
          class="container-fluid mx-auto items-container"
          :style="{ height: height + 'px' }"
        >
          <div
            class="no_toc text-shadow description p-2 rounded"
            :class="[
              data.half_hero ? 'd-lg-none' : '',
              data.text_position,
              data.text_position === 'centered' ? 'text-center' : 'text-left'
            ]"
            :style="{'background-color': 'transparent'}"
            v-if="slide.description"
          >
            <span
              :style="[{ color: data.theme.primary_txt }]"
              class="ck-content"
              v-html="slide.description"
            ></span>
          </div>
        </div>
      </splide-slide>
    </splide>
  </div>
</template>
<script>
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";

export default {
  props: {
    data: Object,
    height: Number
  },
  data() {
    return {
      update: 0,
      options: {
        type: "loop",
        fixedWidth: "100%",
        height: this.height + "px",
        perPage: 1,
        direction: "ltr",
        autoplay: true,
        interval: 3000,
        pauseonHover: true,
        drag: true,
        keyboard: true,
        flickMaxPages: 1,
        accessibility: true,
        lazyLoad: "nearby",
        cover: true,
      }
    };
  },
  computed: {
    cssVars() {
      return {
        "--size": "cover"
      };
    }
  },
  components: {
    Splide,
    SplideSlide
  },
  methods: {
    changedSlide(data) {
      this.$emit("change", data);
    },
    link(slide) {
      let index = slide.index;
      let file = this.data.files[index];

      let link = null;
    }
  }
};
</script>
<style scoped>

.slide {
  background-size: var(--size) !important;
  cursor: pointer;
}

.items-container {
  position: relative;
  text-align: center;
}

.black-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

/* Bottom left text */
.bottom-left {
  position: absolute;
  bottom: 8px;
  left: 16px;
  z-index: 3;
}

/* Top left text */
.top-left {
  position: absolute;
  top: 8px;
  left: 16px;
  z-index: 3;
}

/* Top right text */
.top-right {
  position: absolute;
  top: 8px;
  right: 16px;
  z-index: 3;
}

/* Bottom right text */
.bottom-right {
  position: absolute;
  bottom: 8px;
  right: 16px;
  z-index: 3;
}

/* Centered text */
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 3;
  transform: translate(-50%, -50%);
}
</style>
