<template>
  <div class="container-fluid">
    <div class="row d-flex justify-content-start">
      <div class="col-md-4" v-for="(section, index) in linkToShow" :key="index">
        <div class="row d-flex justify-content-start pr-4">
          <div class="col-12 p-0 mb-4 divider">
            <a
              class="font-weight-bold text-uppercase h6"
              target="_self"
              href="#"
              :style="[{ color: theme.secondary_txt }]"
              >{{ section.name }}</a
            >
          </div>
          <span v-if="!section.page_id">
            <ul
              class="ml-2 col-12 pl-5"
              v-for="i in Math.ceil(
                section.menu_elements.length / elementsPerColumn
              )"
              :key="i"
            >
              <li
                v-for="element in section.menu_elements.slice(
                  (i - 1) * elementsPerColumn,
                  i * elementsPerColumn
                )"
                :key="element.id"
              >
                <a
                  href="#"
                  target="_self"
                  class="list-item"
                  :style="[{ color: theme.secondary_txt }]"
                  >{{ element.label }}</a
                >
              </li>
            </ul>
          </span>
        </div>
      </div>
    </div>
    <div class="mb-4">
      <div style="display: inline-block" class="my-2" :class="width <= 992 ? 'w-100 divider' : null" v-for="(section, index) in sectionsWithoutElements" :key="index">
        <a
          class="font-weight-bold text-uppercase h6"
          target="_self"
          href="#"
          :style="[{ color: theme.secondary_txt }]"
          >{{ section.name }}</a
        >
        <span v-if="index !== sectionsWithoutElements.length - 1 && width > 992" class="font-weight-bold h6 mx-2">-</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MenuFooter",
  data() {
    return {
      elementsPerColumn: 5,
      linkToShow: [],
      sectionsWithoutElements: [],
      width: $(window).width()
    };
  },
  props: {
    links: Array,
    theme: Object
  },
  beforeMount() {
    this.links.forEach((arrayLink) => {
      arrayLink.menu.menu_sections.forEach((link) => {
        if (link.menu_elements.length) {
          this.linkToShow.push(link);
        } else {
          this.sectionsWithoutElements.push(link);
        }
      });
    });
  }
};
</script>

<style scoped>
a.list-item:hover {
  text-decoration: underline !important;
}

.divider {
  border-bottom: solid 0.5px rgba(255, 255, 255, 0.5);
}
</style>
