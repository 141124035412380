<template>
  <div class="container-fluid">
    <div class="row d-flex align-items-center justify-content-center">
      <div
        class="col-lg-1 col-md-2 col-3 d-flex align-items-center justify-content-center"
      >
        <a class="m-4 social" href="#" target="__self" :style="[{'color':theme.secondary_txt}]">
          <font-awesome-icon :icon="['fab','facebook']" size="2x"></font-awesome-icon>
        </a>
      </div>
      <div
        class="col-lg-1 col-md-2 col-3 d-flex align-items-center justify-content-center"
      >
        <a class="m-4 social" href="#" target="__self" :style="[{'color':theme.secondary_txt}]">
          <font-awesome-icon :icon="['fab','linkedin']" size="2x"></font-awesome-icon>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Social",
  props: {
    socialList: {
      required: false,
      default: []
    },
    theme: Object
  }
};
</script>

<style scoped>
.social:hover {
  opacity: 0.8;
  cursor: pointer;
}
</style>
