import { createApp } from 'vue'
import App from './App.vue'
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faUserCircle,
  faArrowLeft,
  faSearch,
  faCookieBite
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faLinkedin
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LTooltip,
  LIcon
} from "@vue-leaflet/vue-leaflet";
import "bootstrap-italia/dist/css/bootstrap-italia.min.css";

library.add(
  faUserCircle,
  faArrowLeft,
  faSearch,
  faCookieBite,
  faFacebook,
  faLinkedin
);

createApp(App)
  .component("font-awesome-icon", FontAwesomeIcon)
  .component("l-map", LMap)
  .component("l-tile-layer", LTileLayer)
  .component("l-marker", LMarker)
  .component("l-popup", LPopup)
  .component("l-tooltip", LTooltip)
  .component("l-icon", LIcon)
  .mount('#app')

  createApp(App)
    .mount('#view')
