<template>
  <div style="background-color: rgb(226, 109, 90);">
    <div class="container-fluid">
      <div class="d-flex align-items-center py-3 justify-content-between" style="color: rgb(255, 255, 255);">
        <div class="row d-flex align-items-start align-items-md-center justify-content-start w-100">
          <div class="col-2 col-md-1 px-2">
            <img alt="Benvenuti nel nostro nuovo sito!" class="img-fluid rounded" sizes="90px" src="/assets/alert.png" />
          </div>
          <div class="col-9 col-md-11">
            <p class="h3">
              Benvenuti nel nostro nuovo sito!
            </p>
            <p class="m-0 ck-content">
              L'Istituto Comprensivo di Scuole in Cloud si rinnova con un nuovo sito web in cui trovare le informazioni che cercate in modo semplice ed intuitivo usando tag, categorie, ricerca in tutti i contenuti caricati ed altro ancora
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
