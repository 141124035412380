<template>
  <div id="header">
    <Search
      :show="show"
      :tag="tag"
      @change="searchEvent"
      @tagDelete="tagDelete"
    ></Search>
    <normal-header :menusImport="menus" :parameters="parameters" @showSearch="showSearch"></normal-header>
    <small-header :menusImport="menus" :parameters="parameters" @showSearch="showSearch"></small-header>
  </div>
</template>
<script>
import Search from "./Search";
import NormalHeader from "./NormalHeader";
import SmallHeader from "./SmallHeader";

export default {
  data() {
    return {
      show: false,
      tag: ""
    };
  },
  components: {
    Search,
    "normal-header": NormalHeader,
    "small-header": SmallHeader
  },
  props: {
    parameters: Object,
    menus: Object
  },
  methods: {
    tagDelete() {
      this.tag = "";
    },
    searchTag(value) {
      this.tag = value;
      this.showSearch();
    },
    showSearch() {
      this.show = true;
      $("body,html").css({ "overflow-y": "hidden" });
    },
    searchEvent(value) {
      this.show = value;
      $("body,html").css({ "overflow-y": "initial" });
    }
  }
};
</script>
