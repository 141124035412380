<template>
  <div style="background-color: rgb(249, 249, 249);">
    <div class="container-fluid py-3">
      <div style="margin-top: 0px;">
        <div class="row m-0 d-flex justify-content-center" style="min-width: 100%;">
          <div class="px-1 py-md-3 py-2 col-md-4 col-lg-3">
            <div class="card-wrapper d-flex justify-content-center p-0">
              <div class="card card-img no-after bookmark rounded">
                <div class="card-body py-2 py-md-4 d-flex justify-content-center align-items-center rounded" style="overflow: hidden; background-color: rgb(11, 83, 148); cursor: pointer;">
                  <p class="card-title m-0 font-weight-bold" style="color: rgb(255, 255, 255); font-size: 27px;">
                    Registro Docenti
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="px-1 py-md-3 py-2 col-md-4 col-lg-3">
            <div class="card-wrapper d-flex justify-content-center p-0">
              <div class="card card-img no-after bookmark rounded">
                <div class="card-body py-2 py-md-4 d-flex justify-content-center align-items-center rounded" style="overflow: hidden; background-color: rgb(56, 118, 29); cursor: pointer;">
                  <p class="card-title m-0 font-weight-bold" style="color: rgb(255, 255, 255); font-size: 27px;">
                    Registro Famiglie
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="px-1 py-md-3 py-2 col-md-4 col-lg-3">
            <div class="card-wrapper d-flex justify-content-center p-0">
              <div class="card card-img no-after bookmark rounded">
                <div class="card-body py-2 py-md-4 d-flex justify-content-center align-items-center rounded" style="overflow: hidden; background-color: rgb(153, 0, 0); cursor: pointer;">
                  <p class="card-title m-0 font-weight-bold" style="color: rgb(255, 255, 255); font-size: 27px;">
                    Iscrizioni 2021-22
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="px-1 py-md-3 py-2 col-md-4 col-lg-3">
            <div class="card-wrapper d-flex justify-content-center p-0">
              <div class="card card-img no-after bookmark rounded">
                <div class="img-responsive-wrapper rounded-bottom" style="background-color: rgb(255, 255, 255);">
                  <div>
                    <a href="#" target="_self" class="d-flex justify-content-center" style="height: auto;">
                      <img title="img title" alt="immagine" style="max-width: 100%; object-fit: scale-down;" sizes="309px" src="/assets/pago.jpg">
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
