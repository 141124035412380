<template>
  <div :id="'parent-contact-height-' + id">
    <div class="h-100">
      <l-map
        ref="map"
        class="d-flex w-100"
        :style="{ 'min-height': '250px' }"
        :zoom="zoom"
        :options="mapOptions"
        :center="center"
        @update:center="centerUpdate"
        @update:zoom="zoomUpdate"
      >
        <span :key="key">
          <l-marker :lat-lng="[pin.x, pin.y]" v-for="(pin, i) in pins" :key="i">
            <l-icon :icon-url="defIcon" :icon-size="[32, 32]" />
            <l-popup>
              <div>
                <p class="font-weight-semibold def-color">
                  {{ pin.label.title }}
                </p>
                <p class="def-color" v-if="pin.label.description">
                  {{ pin.label.description }}
                </p>
              </div>
            </l-popup>
          </l-marker>
        </span>
        <l-tile-layer :url="url" :attribution="attribution" />
      </l-map>
    </div>
  </div>
</template>

<script>
import { latLng } from "leaflet";

export default {
  name: "Maps",
  props: {
    onlyMap: Boolean,
    id: Number,
    footer: {
      required: false,
      default: false,
      type: Boolean
    },
    frame: {
      required: false,
      type: String
    },
    pins: {
      required: false,
      type: Object,
      default: () => {
        return {};
      }
    },
    logo: Boolean,
    custom: {
      required: false,
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      key: 0,
      defIcon: this.Iconlink(),
      zoom: 13,
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      mapOptions: {
        zoomSnap: 0.5
      },
      currentZoom: 13,
      center: this.calculateMean(),
      mapHeight: this.getHeight()
    };
  },
  methods: {
    getHeight() {
      return $("#parent-contact-height-" + this.id).height();
    },
    calculateMean() {
      let pins = this.pins;
      let meanX = 0;
      let meanY = 0;

      for (const [key, pin] of Object.entries(pins)) {
        meanX += pin.x;
        meanY += pin.y;
      }

      meanX = meanX / Object.keys(pins).length;
      meanY = meanY / Object.keys(pins).length;

      return meanX && meanY
        ? latLng(meanX, meanY)
        : latLng(45.9671457, 12.6556298);
    },
    calculateZoom() {
      let pins = this.pins;
      let maxDistance = 0;
      let bounds = [];

      for (const [key, pin] of Object.entries(pins)) {
        if (Object.keys(pins).length > 1) {
          for (const [key, inner] of Object.entries(pins)) {
            if (inner.label.title !== pin.label.title) {
              let distanceX = Math.pow(inner.x - pin.x, 2);
              let distanceY = Math.pow(inner.y - pin.y, 2);
              let tempDistance = Math.sqrt(distanceX + distanceY);

              if (tempDistance > maxDistance) maxDistance = tempDistance;
              bounds = [
                [inner.x, inner.y],
                [pin.x, pin.y]
              ];
            }
          }
        }
      }

      if (maxDistance) this.$refs.map.mapObject.fitBounds(bounds);
    },
    getCoordinates(x, y) {
      return latLng(x, y);
    },
    Iconlink() {
      return "/assets/logo-small.png";
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    }
  }
};
</script>
<style scoped>
  @import "~leaflet/dist/leaflet.css";

  .w-screen {
    width: 100vw !important;
  }
</style>
