<template>
  <div style="background-color: rgb(255, 255, 255); color: rgb(0, 0, 0);">
    <div class="container-fluid py-3">
      <div class="row d-flex align-items-start">
        <div class="col-12">
          <a href="#" style="color: rgb(0, 0, 0);">
            <h3 class="font-weight-semibold">
              Scuola, oggi la premiazione del Concorso Nazionale “Libertà, Diritti, Doveri: cittadinanza italiana e appartenenza Europea”
            </h3>
          </a>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="px-2 col-12">
              <div class="row">
                <div class="news-text col-12">
                  <div class="text-justify my-3 line-clamp ck-content apply-stacktable" style="color: rgb(0, 0, 0);">
                    <div>
                      <span class="text-justify">
                        <p style="margin-left:0px;">Un concorso per coinvolgere studentesse e studenti delle Scuole primarie e secondarie in percorsi di educazione alla Cittadinanza globale, attiva e solidale, ispirati al Manifesto di Ventotene per l’Europa Unita, ai valori della nostra Costituzione, ai luoghi e agli episodi che hanno contribuito alla nascita dell’Italia democratica. </p>
                      </span>
                    </div>
                  </div>
                  <a href="#" style="color: rgb(0, 0, 0);">Leggi notizia intera...</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <hr>
          <small class="font-italic" style="color: rgb(0, 0, 0);">
            <b>Pubblicato:</b>
            13 ottobre 2021
          </small>
        </div>
      </div>
    </div>
  </div>
</template>
