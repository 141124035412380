<template>
  <div class="it-brand-text" v-if="name">
      <h5
        v-for="(word,index) in name.split(',')"
        :key="index"
        :class="[{'d-inline': inline}]"
        class="name py-1 m-0"
      >{{word}}</h5>
    </div>
</template>

<script>
export default {
  name: "NomeIstituto",
  data() {
    return {};
  },
  props: {
    name: String,
    inline: Boolean
  }
};
</script>

<style scoped>
@media (max-width: 360px) {
  .name {
    font-size: 1rem;
    padding-left: 10px;
  }
}
</style>
