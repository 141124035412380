<template>
  <div id="search-window">
    <div class="d-flex align-items-start search scrollbar">
      <div class="container mt-5 p-3">
        <div class="row">
          <div class="col-12 px-4 d-flex align-items-center mb-5">
            <a href="#">
              <font-awesome-icon
                icon="arrow-left"
                size="2x"
                class="text-black icon-title mt-1"
                @click="closeSearch"
              ></font-awesome-icon>
            </a>
            <h2 class="m-0 pl-5 text-black title">Cerca</h2>
          </div>
          <div
            class="col-12 px-4 d-flex align-items-center justify-content-center"
          >
            <div class="input-group mb-3 d-flex align-items-center">
              <input
                type="text"
                class="form-control border-0"
                id="searchBar"
                placeholder="Cerca..."
                v-model="search"
              />
            </div>
          </div>
        </div>
        <div
          class="row d-flex align-items-center justify-content-md-start justify-content-center my-5"
        >
          <button
            :class="[
              'btn',
              'pill-style',
              'm-2',
              'btn-sm',
              'font-weight-bold',
              'rounded-pill',
              filter.indexOf('all') !== -1 ? 'selected' : ''
            ]"
            id="all"
            @click="selectFilter('all')"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              class="mr-1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
                stroke="#4A5568"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Tutto
          </button>
          <button
            :class="[
              'btn',
              'pill-style',
              'm-2',
              'btn-sm',
              'font-weight-bold',
              'rounded-pill',
              filter.indexOf('info') !== -1 ? 'selected' : ''
            ]"
            id="info"
            @click="selectFilter('info')"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              class="mr-1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 20H5C3.89543 20 3 19.1046 3 18L3 6C3 4.89543 3.89543 4 5 4L15 4C16.1046 4 17 4.89543 17 6V7M19 20C17.8954 20 17 19.1046 17 18L17 7M19 20C20.1046 20 21 19.1046 21 18V9C21 7.89543 20.1046 7 19 7L17 7M13 4L9 4M7 16H13M7 8H13V12H7V8Z"
                stroke="#4B5563"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              /></svg
            >Notizie
          </button>
          <button
            :class="[
              'btn',
              'pill-style',
              'm-2',
              'btn-sm',
              'font-weight-bold',
              'rounded-pill',
              filter.indexOf('docs') !== -1 ? 'selected' : ''
            ]"
            id="docs"
            @click="selectFilter('docs')"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              class="mr-1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 7V15C8 16.1046 8.89543 17 10 17H16M8 7V5C8 3.89543 8.89543 3 10 3H14.5858C14.851 3 15.1054 3.10536 15.2929 3.29289L19.7071 7.70711C19.8946 7.89464 20 8.149 20 8.41421V15C20 16.1046 19.1046 17 18 17H16M8 7H6C4.89543 7 4 7.89543 4 9V19C4 20.1046 4.89543 21 6 21H14C15.1046 21 16 20.1046 16 19V17"
                stroke="#4A5568"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              /></svg
            >Documenti
          </button>
          <button
            :class="[
              'btn',
              'pill-style',
              'm-2',
              'btn-sm',
              'font-weight-bold',
              'rounded-pill',
              filter.indexOf('services') !== -1 ? 'selected' : ''
            ]"
            id="services"
            @click="selectFilter('services')"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              class="mr-1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 5C5 3.89543 5.89543 3 7 3H17C18.1046 3 19 3.89543 19 5V21L12 17.5L5 21V5Z"
                stroke="#4A5568"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              /></svg
            >Banner
          </button>
          <button
            :class="[
              'btn',
              'pill-style',
              'm-2',
              'btn-sm',
              'font-weight-bold',
              'rounded-pill',
              filter.indexOf('pages') !== -1 ? 'selected' : ''
            ]"
            id="pages"
            @click="selectFilter('pages')"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              class="mr-1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.8284 10.1716C12.2663 8.60948 9.73367 8.60948 8.17157 10.1716L4.17157 14.1716C2.60948 15.7337 2.60948 18.2663 4.17157 19.8284C5.73367 21.3905 8.26633 21.3905 9.82843 19.8284L10.93 18.7269M10.1716 13.8284C11.7337 15.3905 14.2663 15.3905 15.8284 13.8284L19.8284 9.82843C21.3905 8.26633 21.3905 5.73367 19.8284 4.17157C18.2663 2.60948 15.7337 2.60948 14.1716 4.17157L13.072 5.27118"
                stroke="#4A5568"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              /></svg
            >Menù e Pagine
          </button>
          <button
            :class="[
              'btn',
              'pill-style',
              'm-2',
              'btn-sm',
              'font-weight-bold',
              'rounded-pill',
              filter.indexOf('tag') !== -1 ? 'selected' : ''
            ]"
            id="tags"
            @click="selectFilter('tag')"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              class="mr-1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 20L11 4M13 20L17 4M6 9H20M4 15H18"
                stroke="#4A5568"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Tag
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Search",

  data() {
    return {
      filter: ["all"],
      search: "",
      results: [],
      timeout: null
    };
  },
  props: {
    show: Boolean,
    tag: String
  },
  methods: {
    //Handles click on tag events from other components
    searchTag() {
      if (this.tag.replace(/\s/g, "").length) {
        this.filter = ["tag"];
        this.search = this.tag;
      }
    },
    showSearch() {
      $("#search-window").show();
      $(".search").animate({ height: "100%" }, 200);
    },
    //close search window
    closeSearch() {
      this.results = [];
      this.search = "";
      this.selectFilter("all");
      $(".search").animate({ height: "0" }, 200, () => {
        $("#search-window").hide();
        this.$emit("tagDelete");
        this.$emit("change", false);
      });
    },
    //select filter
    selectFilter(filter) {
      if (filter === "all") {
        this.filter = [];
        $("#searchBar").attr("placeholder", "Cerca...");
      } else {
        if (this.filter.indexOf("all") !== -1) {
          this.filter.splice(this.filter.indexOf("all"), 1);
        }
      }

      if (filter === "tag") {
        this.filter = [];
        $("#searchBar").attr("placeholder", "#");
      } else {
        if (this.filter.indexOf("tag") !== -1) {
          this.filter.splice(this.filter.indexOf("tag"), 1);
          $("#searchBar").attr("placeholder", "Cerca...");
        }
      }

      if (this.filter.indexOf(filter) === -1) {
        this.filter.push(filter);
      } else {
        this.filter.splice(this.filter.indexOf(filter), 1);
      }

      if (this.filter.length === 0) {
        this.filter.push("all");
      }
    },
  },
  watch: {
    show: function() {
      if (this.show) {
        this.showSearch();
      }
    }
  }
};
</script>

<style scoped>
#search-window {
  display: none;
}

#searchBar {
  border-bottom: #4299e1 2px solid !important;
  font-weight: bold !important;
}

.hashtag {
  border: solid thin gray;
  border-radius: 15px;
  color: black;
  background-color: transparent;
}

.pill-style {
  border-bottom: solid 1px #4299e1;
  border-radius: 10px;
  color: black;
}

.line-clamp {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.search {
  background-color: white;
  width: 100%;
  height: 0;
  position: fixed;
  top: 0;
  left: 0;
  margin: auto;
  z-index: 999999;
  overflow-y: auto;
}

input {
  font-size: 1.2rem;
  font-weight: lighter;
}

input::placeholder {
  font-size: 1.2rem;
}

.selected {
  transition-duration: 500;
  background-color: #ebf8ff;
  border: none !important;
}

a:hover {
  text-decoration: underline !important;
  color: black !important;
}

.icon-title:hover {
  opacity: 0.7;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  input,
  .icon-search {
    font-size: 1rem;
  }

  .title {
    font-size: 2rem !important;
  }

  .icon-title {
    font-size: 1.7rem !important;
  }
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
}
</style>
