<template>
  <div style="background-color: rgb(0, 119, 182);">
    <div class="container-fluid">
      <a href="#" target="_self" class="d-flex align-items-center py-3 justify-content-between" style="color: rgb(255, 255, 255);">
        <div class="row d-flex align-items-start align-items-md-center justify-content-start w-100">
          <div class="col-12 col-md-11">
            <p class="h3">
              Con chi lavoriamo
            </p>
          </div>
        </div>
      </a>
    </div>
  </div>
  <div style="background-color: rgb(255, 255, 255);">
    <div class="container-fluid text-center py-3">
      <div class="row p-1 d-flex align-items-center justify-content-center">
        <div class="col-lg-3 col-12 p-1">
          <div class="d-flex align-items-center justify-content-center" style="height: 100px; background-color: rgba(204, 162, 180, 0.2);">
            <a href="#" target="_self">
              <img alt="partner 1" class="img-fluid p-1" style="max-height: 100px;" sizes="320px" src="/assets/partner1.png">
            </a>
          </div>
        </div>
        <div class="col-lg-3 col-12 p-1">
          <div class="d-flex align-items-center justify-content-center" style="height: 100px; background-color: rgba(105, 190, 136, 0.2);">
            <a href="#" target="_self">
              <img alt="partner 2" class="img-fluid p-1" style="max-height: 100px;" sizes="326px" src="/assets/partner2.png">
            </a>
          </div>
        </div>
        <div class="col-lg-3 col-12 p-1">
          <div class="d-flex align-items-center justify-content-center" style="height: 100px; background-color: rgba(139, 14, 14, 0.2);">
            <a href="#" target="_self">
              <img alt="partner 3" class="img-fluid p-1" style="max-height: 100px;" sizes="330px" src="/assets/partner3.png">
            </a>
          </div>
        </div>
        <div class="col-lg-3 col-12 p-1">
          <div class="d-flex align-items-center justify-content-center" style="height: 100px; background-color: rgba(133, 125, 175, 0.2);">
            <a href="#" target="_self">
              <img alt="partner 4" class="img-fluid p-1" style="max-height: 100px;" sizes="327px" src="/assets/partner4.png">
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
